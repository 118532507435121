import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Citas } from '../models/citas';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

moment.locale('en');

@Injectable({
  providedIn: 'root'
})

export class CitasService {


  constructor(private http: HttpClient) { }

  getCitas(): Observable<Citas[]> {
    return this.http.get<any>(`${environment.url_api}getCita`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.Citas)).map(item => {
        return new Citas(
          item.id_cita,
          item.uidUser,
          item.createAt,
          item.tipoCita
        );
      });
    })
    );
  }


}